/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import getDevices from '../components/devices/getDevices/redux/reducer';
import {
  signUpEpic,
  signInEpic,
  signUpConfirmCodeEpic,
  confirmPasswordEpic,
  forgotPasswordEpic,
  signOutEpic,
  initialUser,
} from '../components/sign_in/redux/epics';
import reducerValid, {
  signUpConfirmCodeReducer,
  openHomeScreenReducer,
  modalReducer,
  saveStateReducer,
  signInReducer,
  forgotPasswordReducer,
  confirmPasswordReducer,
  signOutReducer,
  signUpReducer,
  terminalComponentReducer,
} from '../components/sign_in/redux/reducer';

const rootEpic = combineEpics(
  signUpEpic,
  signInEpic,
  signUpConfirmCodeEpic,
  confirmPasswordEpic,
  forgotPasswordEpic,
  signOutEpic,
  initialUser,
);

const rootReducer = combineReducers({
  getDevices,
  saveStateReducer,
  modal: modalReducer,
  openHomeScreenReducer,
  terminalComponentReducer,
  reducerValid,
  signUpConfirmCodeReducer,
  signInReducer,
  forgotPasswordReducer,
  confirmPasswordReducer,
  signOutReducer,
  signUpReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
