export type LoginAction =
  | OpenModal
  | CloseModal
  | OpenHomescreen
  | CloseHomescreen
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  | LoginForm
  | InitialForm
  | EmptyAction
  | SaveStateActionTypes
  | DeleteStateTypes
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  | SignUp
  | SignupSucceeded
  | SignupFailed
  | SignupConfirmCode
  | SignupConfirmCodeFailed
  | SignupConfirmCodeSucceeded
  | Signin
  | SigninSucceeded
  | SigninFailed
  | ForgotPassword
  | ForgotPasswordForm
  | ForgotPasswordSucceeded
  | ForgotPasswordFailed
  | SignOut
  | SignOutSucceeded
  | SignOutFailed
  | ConfirmPassword
  | ConfirmPasswordSucceeded
  | ConfirmPasswordFailed
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  | InitClient
  | InitClientSuccess
  | InitClientFailed
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  | SetEmail
  | SetEmailError
  | SetPassword
  | SetPasswordError
  | SetConfirmPassword
  | SetConfirmPasswordError
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  | SmsErrorAction
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  | SetOtpAction
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  | SignupEmailRequest
  | SignupPasswordRequest
  | EmailRegistrationForm
  | PasswordRegistrationForm
  | CodeRegistrationForm
  | CodeRegistrationForm
  //'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  // | SetCurrentUser
  | SigninReset
  | ClearResultError;

export enum MainSteps {
  INITIAL_FORM = 'INITIAL_FORM',
  LOGIN_FORM = 'LOGIN_FORM',
  SAVE_STATE = 'SAVE_STATE',
  DELETE_STATE = 'DELETE_STATE',
  REGISTER_EMAIL = 'REGISTER_EMAIL',
  EMAIL_VALID = 'EMAIL_VALID',
  REGISTER_PASSWORD = 'REGISTER_PASSWORD',
  REGISTER_CODE = 'REGISTER_CODE',
  REGISTER_VERIFY = 'REGISTER_VERIFY',
  REGISTRATION_PASSWORD_FORM = 'REGISTRATION_PASSWORD_FORM',
  REGISTRATION_CODE_FORM = 'REGISTRATION_CODE_FORM',
  REGISTRATION_EMAIL_FORM = 'REGISTRATION_EMAIL_FORM',
  SIGNIN_LOGIN = 'SIGNIN_LOGIN',
  SIGNIN_PASSWORD_RECOVERY = 'SIGNIN_PASSWORD_RECOVERY',
  SIGNIN_RECEIVE_CODE = 'SIGNIN_RECEIVE_CODE',
  SIGNIN_NEW_PASSWORD = 'SIGNIN_NEW_PASSWORD',
  FORGOT_PASSWORD_MODAL = 'FORGOT_PASSWORD_MODAL',
  SEND_CODE_FOR_NEW_PASSWORD_MODAL = 'SEND_CODE_FOR_NEW_PASSWORD_MODAL',
  SET_NEW_PASSWORD_FORM = 'SET_NEW_PASSWORD_FORM',
}

export enum LoginActionTypes {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
  OPEN_HOMESCREEN = 'OPEN_HOMESCREEN',
  CLOSE_HOMESCREEN = 'CLOSE_HOMESCREEN',
  OPEN_TERMINAL = 'OPEN_TERMINAL',
  CLOSE_TERMINAL = 'CLOSE_TERMINAL',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  SIGNUP = 'SIGNUP',
  SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
  SIGNUP_FAILED = 'SIGNUP_FAILED',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  LOGIN_FORM = 'LOGIN_FORM',
  INITIAL_FORM = 'INITIAL_FORM',
  OPEN_BLOCK = 'OPEN_BLOCK',
  CLOSE_BLOCK = 'CLOSE_BLOCK',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  EMAIL_REGISTRATION_FORM = 'EMAIL_REGISTRATION_FORM',
  PASSWORD_REGISTRATION_FORM = 'PASSWORD_REGISTRATION_FORM',
  CODE_REGISTRATION_FORM = 'CODE_REGISTRATION_FORM',
  SEND_CODE_FOR_NEW_PASSWORD_FORM = 'SEND_CODE_FOR_NEW_PASSWORD_FORM',
  FORGOT_PASSWORD_FORM = 'FORGOT_PASSWORD_FORM',
  NEW_USER_REGISTRATION = 'NEW_USER_REGISTRATION',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCEEDED = 'FORGOT_PASSWORD_SUCCEEDED',
  FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  SIGNUP_CONFIRM_CODE = 'SIGNUP_CONFIRM_CODE',
  SIGNUP_CONFIRM_CODE_SUCCEEDED = 'SIGNUP_CONFIRM_CODE_SUCCEEDED',
  SIGNUP_CONFIRM_CODE_FAILED = 'SIGNUP_CONFIRM_CODE_FAILED',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  SIGNUP_EMAIL_REQUEST = 'SIGNUP_EMAIL_REQUEST',
  SIGNUP_PASSWORD_REQUEST = 'SIGNUP_PASSWORD_REQUEST',
  SIGNIN = 'SIGNIN',
  SIGNIN_SUCCEEDED = 'SIGNIN_SUCCEEDED',
  SIGNIN_FAILED = 'SIGNIN_FAILED',
  SIGN_OUT = 'SIGN_OUT',
  SIGN_OUT_SUCCEEDED = 'SIGN_OUT_SUCCEEDED',
  SIGN_OUT_FAILED = 'SIGN_OUT_FAILED',
  SEND_CODE_FOR_NEW_PASSWORD_REQUEST = 'SEND_CODE_FOR_NEW_PASSWORD_REQUEST',
  SET_STEP = 'SET_STEP',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  CONFIRM_PASSWORD_SUCCEEDED = 'CONFIRM_PASSWORD_SUCCEEDED',
  CONFIRM_PASSWORD_FAILED = 'CONFIRM_PASSWORD_FAILED',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  SET_NEW_PASSWORD_SUCCEEDED = 'SET_NEW_PASSWORD_SUCCEEDED',
  SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST',
  DEFAULT_ACTION_TYPE = 'DEFAULT_ACTION_TYPE',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  // email & password
  SET_EMAIL = 'SET_EMAIL',
  SET_EMAIL_ERROR = 'SET_EMAIL_ERROR',
  SET_PASSWORD = 'SET_PASSWORD',
  SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR',
  SET_CONFIRM_PASSWORD = 'SET_CONFIRM_PASSWORD',
  SET_CONFIRM_PASSWORD_ERROR = 'SET_CONFIRM_PASSWORD_ERROR',
  SET_SMS_ERROR = 'SET_SMS_ERROR',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  // otp input
  SET_OTP = 'SET_OTP',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  //'''''''''''''''''''''''''''''''''''''''
  SIGNIN_RESET = 'SIGNIN_RESET',
  CLEAR_RESULT_ERROR = 'CLEAR_RESULT_ERROR',
  //''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
  INIT_CLIENT = 'INIT_CLIENT',
  INIT_CLIENT_SUCCESS = 'INIT_CLIENT_SUCCESS',
  INIT_CLIENT_FAILED = 'INIT_CLIENT_FAILED',
}

//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// export interface SetCurrentUser {
//   type: LoginActionTypes.SET_CURRENT_USER;
//   currentUser: string;
// }
// export function setCurrentUser(currentUser: string): SetCurrentUser {
//   return {
//     type: LoginActionTypes.SET_CURRENT_USER,
//     currentUser,
//   };
// }
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface SigninReset {
  type: LoginActionTypes.SIGNIN_RESET;
}
export function signinReset(): SigninReset {
  return {
    type: LoginActionTypes.SIGNIN_RESET,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface ClearResultError {
  type: LoginActionTypes.CLEAR_RESULT_ERROR;
  payload: string;
}
export function clearResultError(error: string): ClearResultError {
  return {
    type: LoginActionTypes.CLEAR_RESULT_ERROR,
    payload: error,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface EmptyAction {
  type: LoginActionTypes.DEFAULT_ACTION_TYPE;
}

export interface SignupEmailRequest {
  type: LoginActionTypes.SIGNUP_EMAIL_REQUEST;
  username: string;
}

export interface SignupPasswordRequest {
  type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST;
  username: string;
  password: string;
  passwordRepeat: string;
}

//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface Signin {
  type: LoginActionTypes.SIGNIN;
  username: string;
  password: string;
}
export interface SigninSucceeded {
  type: LoginActionTypes.SIGNIN_SUCCEEDED;
  result: {};
}
export interface SigninFailed {
  type: LoginActionTypes.SIGNIN_FAILED;
  error: Error;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''

export interface NewUserRegistration {
  type: LoginActionTypes.NEW_USER_REGISTRATION;
}
export interface SaveStateActionTypes {
  type: MainSteps.SAVE_STATE;
  state: any;
}
export interface DeleteStateTypes {
  type: MainSteps.DELETE_STATE;
  state: {
    name: null;
  };
}
export interface LoginRequest {
  username: string;
  password: string;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface SignUp {
  type: LoginActionTypes.SIGNUP;
  username: string;
  password: string;
}
export interface SignupFailed {
  type: LoginActionTypes.SIGNUP_FAILED;
  error: Error;
}
export interface SignupSucceeded {
  type: LoginActionTypes.SIGNUP_SUCCEEDED;
  result: {};
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// email && code
export interface SignupConfirmCode {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE;
  username: string;
  verificationCode: string;
}
export interface SignupConfirmCodeSucceeded {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED;
  result: {};
}
export interface SignupConfirmCodeFailed {
  type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED;
  error: Error;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface ForgotPassword {
  type: LoginActionTypes.FORGOT_PASSWORD;
  username: string;
}
export interface ForgotPasswordFailed {
  type: LoginActionTypes.FORGOT_PASSWORD_FAILED;
  error: Error;
}
export interface ForgotPasswordSucceeded {
  type: LoginActionTypes.FORGOT_PASSWORD_SUCCEEDED;
  result: {};
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface SetNewPassword {
  type: LoginActionTypes.SET_NEW_PASSWORD_REQUEST;
  username: string;
  confirmCode: string;
  newPassword: string;
}
export interface SetNewPasswordSucceeded {
  type: LoginActionTypes.SET_NEW_PASSWORD_SUCCEEDED;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface ConfirmPassword {
  type: LoginActionTypes.CONFIRM_PASSWORD;
  username: string;
  verificationCode: string;
  newPassword: string;
}
export interface ConfirmPasswordSucceeded {
  type: LoginActionTypes.CONFIRM_PASSWORD_SUCCEEDED;
  result: {};
}
export interface ConfirmPasswordFailed {
  type: LoginActionTypes.CONFIRM_PASSWORD_FAILED;
  error: Error;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// email && code
export interface SignOut {
  type: LoginActionTypes.SIGN_OUT;
}

export interface SignOutSucceeded {
  type: LoginActionTypes.SIGN_OUT_SUCCEEDED;
  result: {};
}

export interface SignOutFailed {
  type: LoginActionTypes.SIGN_OUT_FAILED;
  error: Error;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// login steps
export interface InitialForm {
  type: LoginActionTypes.INITIAL_FORM;
}

export interface EmailRegistrationForm {
  type: LoginActionTypes.EMAIL_REGISTRATION_FORM;
}

export interface PasswordRegistrationForm {
  type: LoginActionTypes.PASSWORD_REGISTRATION_FORM;
}

export interface CodeRegistrationForm {
  type: LoginActionTypes.CODE_REGISTRATION_FORM;
  result: {};
}

export interface LoginForm {
  type: LoginActionTypes.LOGIN_FORM;
}

export interface ForgotPasswordForm {
  type: LoginActionTypes.FORGOT_PASSWORD_FORM;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// close && open modal
export interface OpenModal {
  type: LoginActionTypes.OPEN_MODAL;
}

export interface CloseModal {
  type: LoginActionTypes.CLOSE_MODAL;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// close && open homescreen
export interface OpenHomescreen {
  type: LoginActionTypes.OPEN_HOMESCREEN;
}

export interface CloseHomescreen {
  type: LoginActionTypes.CLOSE_HOMESCREEN;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// close && open TerminalComponent
export interface OpenTerminal {
  type: LoginActionTypes.OPEN_TERMINAL;
}

export interface CloseTerminal {
  type: LoginActionTypes.CLOSE_TERMINAL;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// email && password
export interface SetEmail {
  type: LoginActionTypes.SET_EMAIL;
  payload: string;
}

export interface SetEmailError {
  type: LoginActionTypes.SET_EMAIL_ERROR;
  payload: string;
}

export interface SetPassword {
  type: LoginActionTypes.SET_PASSWORD;
  payload: string;
}

export interface SetPasswordError {
  type: LoginActionTypes.SET_PASSWORD_ERROR;
  payload: string;
}

// confirm password
export interface SetConfirmPassword {
  type: LoginActionTypes.SET_CONFIRM_PASSWORD;
  payload: string;
}

export interface SetConfirmPasswordError {
  type: LoginActionTypes.SET_CONFIRM_PASSWORD_ERROR;
  payload: string;
}

// smsError
export interface SmsErrorAction {
  type: LoginActionTypes.SET_SMS_ERROR;
  payload: string;
}

// otp input
export interface SetOtpAction {
  type: LoginActionTypes.SET_OTP;
  otp: string;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export interface InitClient {
  type: LoginActionTypes.INIT_CLIENT;
}
export interface InitClientSuccess {
  type: LoginActionTypes.INIT_CLIENT_SUCCESS;
  session: any;
}
export interface InitClientFailed {
  type: LoginActionTypes.INIT_CLIENT_FAILED;
  error: string;
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// функции
export function signupEmailRequest(username: string): SignupEmailRequest {
  return {
    type: LoginActionTypes.SIGNUP_EMAIL_REQUEST,
    username,
  };
}
export function signupPasswordRequest(
  username: string,
  password: string,
  passwordRepeat: string,
): SignupPasswordRequest {
  return {
    type: LoginActionTypes.SIGNUP_PASSWORD_REQUEST,
    username,
    password,
    passwordRepeat,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function signin(username: string, password: string): Signin {
  return {
    type: LoginActionTypes.SIGNIN,
    username,
    password,
  };
}
export function signinSucceeded(result): SigninSucceeded {
  return {
    type: LoginActionTypes.SIGNIN_SUCCEEDED,
    result,
  };
}
export function signinFailed(error: Error): SigninFailed {
  return {
    type: LoginActionTypes.SIGNIN_FAILED,
    error,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function signUp(username: string, password: string): SignUp {
  return {
    type: LoginActionTypes.SIGNUP,
    username,
    password,
  };
}
export function signupFailed(error: Error): SignupFailed {
  return {
    type: LoginActionTypes.SIGNUP_FAILED,
    error,
  };
}
export function signupSucceeded(result): SignupSucceeded {
  return {
    type: LoginActionTypes.SIGNUP_SUCCEEDED,
    result,
  };
}
//'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function signupConfirmCode(username: string, verificationCode: string): SignupConfirmCode {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE,
    username,
    verificationCode,
  };
}
export function signupConfirmCodeSucceeded(result): SignupConfirmCodeSucceeded {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED,
    result,
  };
}
export function signupConfirmCodeFailed(error: Error): SignupConfirmCodeFailed {
  return {
    type: LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED,
    error,
  };
}
//'''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// another
export function loginRequest(username: string, password: string): LoginRequest {
  return {
    username,
    password,
  };
}
export function saveStateAction(state: any): SaveStateActionTypes {
  return {
    type: MainSteps.SAVE_STATE,
    state: state,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function deleteState(): DeleteStateTypes {
  return {
    type: MainSteps.DELETE_STATE,
    state: {
      name: null,
    },
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function forgotPassword(username: string): ForgotPassword {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD,
    username,
  };
}
export function forgotPasswordSucceeded(result): ForgotPasswordSucceeded {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_SUCCEEDED,
    result,
  };
}
export function forgotPasswordFailed(error: Error): ForgotPasswordFailed {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_FAILED,
    error,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function confirmPassword(username: string, verificationCode: string, newPassword: string): ConfirmPassword {
  return {
    type: LoginActionTypes.CONFIRM_PASSWORD,
    username,
    verificationCode,
    newPassword,
  };
}
export function confirmPasswordSucceeded(result): ConfirmPasswordSucceeded {
  return {
    type: LoginActionTypes.CONFIRM_PASSWORD_SUCCEEDED,
    result,
  };
}
export function confirmPasswordFailed(error: Error): ConfirmPasswordFailed {
  return {
    type: LoginActionTypes.CONFIRM_PASSWORD_FAILED,
    error,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// email && code
export function signOut(): SignOut {
  return {
    type: LoginActionTypes.SIGN_OUT,
  };
}
export function signOutSucceeded(result): SignOutSucceeded {
  return {
    type: LoginActionTypes.SIGN_OUT_SUCCEEDED,
    result,
  };
}
export function signOutFailed(error: Error): SignOutFailed {
  return {
    type: LoginActionTypes.SIGN_OUT_FAILED,
    error,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// login steps
export function initialForm(): InitialForm {
  return {
    type: LoginActionTypes.INITIAL_FORM,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function emailRegistrationForm(): EmailRegistrationForm {
  return {
    type: LoginActionTypes.EMAIL_REGISTRATION_FORM,
  };
}
export function passwordRegistrationForm(): PasswordRegistrationForm {
  return {
    type: LoginActionTypes.PASSWORD_REGISTRATION_FORM,
  };
}
export function codeRegistrationForm(result: {}): CodeRegistrationForm {
  return {
    type: LoginActionTypes.CODE_REGISTRATION_FORM,
    result,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function loginForm(): LoginForm {
  return {
    type: LoginActionTypes.LOGIN_FORM,
  };
}
export function forgotPasswordForm(): ForgotPasswordForm {
  return {
    type: LoginActionTypes.FORGOT_PASSWORD_FORM,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// close && open modal
export function openModal(): OpenModal {
  return {
    type: LoginActionTypes.OPEN_MODAL,
  };
}
export function closeModal(): CloseModal {
  return {
    type: LoginActionTypes.CLOSE_MODAL,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// close && open homescreen
export function openHomescreen(): OpenHomescreen {
  return {
    type: LoginActionTypes.OPEN_HOMESCREEN,
  };
}
export function closeHomescreen(): CloseHomescreen {
  return {
    type: LoginActionTypes.CLOSE_HOMESCREEN,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// close && open TerminalComponent
export function openTerminal(): OpenTerminal {
  return {
    type: LoginActionTypes.OPEN_TERMINAL,
  };
}
export function closeTerminal(): CloseTerminal {
  return {
    type: LoginActionTypes.CLOSE_TERMINAL,
  };
}
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// email && password
export const setEmail = (email: string): SetEmail => ({
  type: LoginActionTypes.SET_EMAIL,
  payload: email,
});

export const setEmailError = (error: string): SetEmailError => ({
  type: LoginActionTypes.SET_EMAIL_ERROR,
  payload: error,
});

export const setPassword = (password: string): SetPassword => ({
  type: LoginActionTypes.SET_PASSWORD,
  payload: password,
});

export const setPasswordError = (error: string): SetPasswordError => ({
  type: LoginActionTypes.SET_PASSWORD_ERROR,
  payload: error,
});

// confirm password
export const setConfirmPassword = (password: string): SetConfirmPassword => ({
  type: LoginActionTypes.SET_CONFIRM_PASSWORD,
  payload: password,
});

export const setConfirmPasswordError = (error: string): SetConfirmPasswordError => ({
  type: LoginActionTypes.SET_CONFIRM_PASSWORD_ERROR,
  payload: error,
});
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
// smsError
export const setSmsError = (error: string): SmsErrorAction => ({
  type: LoginActionTypes.SET_SMS_ERROR,
  payload: error,
});

// otp input
export const setOtp = (otp: string): SetOtpAction => ({
  type: LoginActionTypes.SET_OTP,
  otp,
});
//''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''''
export function initialClient(): InitClient {
  return {
    type: LoginActionTypes.INIT_CLIENT,
  };
}
export function initialClientSuccess(session: any): InitClientSuccess {
  return {
    type: LoginActionTypes.INIT_CLIENT_SUCCESS,
    session,
  };
}
export function initialClientFailed(error: string): InitClientFailed {
  return {
    type: LoginActionTypes.INIT_CLIENT_FAILED,
    error,
  };
}
