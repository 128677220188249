export function setLocalStorage(key: string, value: any) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(key, value);
  }
}

export function getItemFromLocalStorage(key: string) {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(key);
  } else {
    return JSON.stringify({ mockData: 'mockData' });
  }
}

export function removeItemFromLocalStorage(key: string) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(key);
  }
}

export const LoginPage = {
  Welcome: 'Welcome',
  SignIn: 'SignIn',
  Registration: 'Registration',
  NewPassword: 'NewPassword',
  GetPassword: 'GetPassword',
  NewPasswordValidation: 'NewPasswordValidation',
  EnterAccount: 'EnterAccount',
  RegistrationModel: 'RegistrationModel',
  RegistrationModelEnter: 'RegistrationModelEnter',
  RegistrationModelCode: 'RegistrationModelCode',
  RegistrationEnterAccount: 'RegistrationEnterAccount',
};

export const mockUsers = [
  {
    id: '1',
    email: 'example1@example.com',
    password: 'password1',
    points: '100',
    profilePhoto: {},
    role: 'user',
    userName: 'User1',
  },
  {
    id: '2',
    email: 'example2@example.com',
    password: 'password2',
    points: '150',
    profilePhoto: {},
    role: 'user',
    userName: 'User2',
  },
];
