import { LoginPage } from '../../../utils/helpers';
import { MainSteps, LoginActionTypes, LoginAction } from './actions';
import update from 'immutability-helper';

export const initialGetDevicesState = {
  currentState: LoginPage.Welcome,
  username: '',
  password: '',
  currentUser: '',
};

export interface User {
  id?: string;
  phoneNumber?: string;
  points?: string;
  profilePhoto?: {};
  role?: string;
  userName?: string;
}

export interface AuthState {
  session?: any;
  user?: boolean;
  email: string;
  loading?: any;
  isSuccess: any;
  result: any;
  resultError: any;
}

export const EpicState: AuthState = {
  loading: false,
  isSuccess: false,
  result: null,
  resultError: null,
  user: false,
  email: '',
};

// signUpReducer
export const signUpReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.SIGNUP:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.SIGNUP_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.SIGNUP_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

// signUpConfirmCodeReducer
export const signUpConfirmCodeReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.SIGNUP_CONFIRM_CODE:
      return {
        ...state,
        loading: true,
        resultError: null,
      };
    case LoginActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
        isSuccess: true,
      };
    case LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

// signInReducer
export const signInReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.INIT_CLIENT:
      return update(state, {
        user: { $set: false },
      });
    case LoginActionTypes.INIT_CLIENT_SUCCESS:
      const emailAttribute = action.session.find((attr) => attr.Name === 'email');
      const emailValue = emailAttribute ? emailAttribute.Value : null;

      return update(state, {
        session: { $set: action.session },
        email: { $set: emailValue },
        user: { $set: true },
      });
    case LoginActionTypes.INIT_CLIENT_FAILED:
      return update(state, {
        user: { $set: false },
      });
    case LoginActionTypes.SIGNIN:
      return {
        ...state,
        loading: true,
        resultError: null,
      };
    case LoginActionTypes.SIGNIN_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
        isSuccess: true,
      };
    case LoginActionTypes.SIGNIN_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    case LoginActionTypes.CLEAR_RESULT_ERROR:
      return {
        ...state,
        resultError: null,
      };
    case LoginActionTypes.SIGNIN_RESET:
      return EpicState;
    default:
      return state;
  }
};

// forgotPasswordReducer
export const forgotPasswordReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.FORGOT_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    case LoginActionTypes.CLEAR_RESULT_ERROR:
      return {
        ...state,
        resultError: null,
      };
    default:
      return state;
  }
};

// confirmPasswordReducer
export const confirmPasswordReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.CONFIRM_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.CONFIRM_PASSWORD_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.CONFIRM_PASSWORD_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    case LoginActionTypes.CLEAR_RESULT_ERROR:
      return {
        ...state,
        resultError: null,
      };
    default:
      return state;
  }
};

// signOutReducer
export const signOutReducer = (state = EpicState, action) => {
  switch (action.type) {
    case LoginActionTypes.SIGN_OUT:
      return {
        ...state,
        loading: true,
      };
    case LoginActionTypes.SIGN_OUT_SUCCEEDED:
      return {
        ...state,
        result: action.result,
        loading: false,
      };
    case LoginActionTypes.SIGN_OUT_FAILED:
      return {
        ...state,
        resultError: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

//* saveStateReducer
export function saveStateReducer(state = initialGetDevicesState, action: LoginAction) {
  switch (action.type) {
    case MainSteps.SAVE_STATE:
      return {
        ...state,
        currentState: action.state,
      };
    case MainSteps.DELETE_STATE:
      return {
        ...state,
        currentState: action.state,
      };
    case LoginActionTypes.SIGNUP:
      return {
        ...state,
        username: action.username,
        password: action.password,
      };
    // case LoginActionTypes.SET_CURRENT_USER:
    //   return {
    //     ...state,
    //     currentUser: action.currentUser,
    //   };
    default:
      return state;
  }
}

// open/close modal
const initialState = {
  isOpen: false,
};
// open/close modal
export function modalReducer(state = initialState, action) {
  switch (action.type) {
    case LoginActionTypes.OPEN_MODAL:
      return {
        ...state,
        isOpen: state.isOpen ? false : true,
      };
    case LoginActionTypes.CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

// open/close homeScreen
const initialOpenHomeScreen = {
  isOpen: true,
};
// open/close homeScreen
export function openHomeScreenReducer(state = initialOpenHomeScreen, action) {
  switch (action.type) {
    case LoginActionTypes.OPEN_HOMESCREEN:
      return {
        ...state,
        isOpen: state.isOpen ? false : true,
      };
    case LoginActionTypes.CLOSE_HOMESCREEN: // на всякий добавила
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
}

// open/close terminalComponent
const initialTerminalState = {
  isOpen: false,
};
// open/close terminalComponent
export function terminalComponentReducer(state = initialTerminalState, action) {
  switch (action.type) {
    case LoginActionTypes.OPEN_TERMINAL:
      return {
        ...state,
        isOpen: state.isOpen ? false : true,
      };
    case LoginActionTypes.CLOSE_TERMINAL:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

// регистрация
export const emailPasswordState = {
  email: '',
  emailError: '',
  password: '',
  passwordError: '',
  setConfirmPassword: '',
  setConfirmPasswordError: '',
  smsError: '',
  setOtp: '',
};
// регистрация
const reducerValid = (state = emailPasswordState, action) => {
  switch (action.type) {
    case LoginActionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case LoginActionTypes.SET_EMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload,
      };
    case LoginActionTypes.SET_PASSWORD:
      return {
        ...state,
        password: action.payload,
      };
    case LoginActionTypes.SET_PASSWORD_ERROR:
      return {
        ...state,
        passwordError: action.payload,
      };
    case LoginActionTypes.SET_CONFIRM_PASSWORD:
      return {
        ...state,
        setConfirmPassword: action.payload,
      };
    case LoginActionTypes.SET_CONFIRM_PASSWORD_ERROR:
      return {
        ...state,
        setConfirmPasswordError: action.payload,
      };
    case LoginActionTypes.SET_SMS_ERROR:
      return {
        ...state,
        smsError: action.payload,
      };
    case LoginActionTypes.SET_OTP:
      return {
        ...state,
        otp: action.otp,
      };
    default:
      return state;
  }
};

export default reducerValid;
