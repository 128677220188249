/* External dependencies */
import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import {
  LoginActionTypes,
  LoginAction,
  LoginRequest,
  Signin,
  signinSucceeded,
  signinFailed,
  signupSucceeded,
  signupFailed,
  ForgotPassword,
  forgotPasswordFailed,
  forgotPasswordSucceeded,
  SignupConfirmCode,
  signupConfirmCodeSucceeded,
  signupConfirmCodeFailed,
  ConfirmPassword,
  confirmPasswordSucceeded,
  confirmPasswordFailed,
  SignOut,
  signOutSucceeded,
  signOutFailed,
  initialClientSuccess,
  initialClientFailed,
} from './actions';
import { getSession } from '../../../client/graphql';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function signUpEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP),
    switchMap(({ password, username }: LoginRequest) =>
      cognitoClient
        .signUp(username, password)
        .then((value: any) => signupSucceeded(value))
        .catch((error) => signupFailed(error)),
    ),
  );
}

export function signInEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNIN),
    switchMap(({ username, password }: Signin) => {
      return cognitoClient.signIn(username, password).then(signinSucceeded).catch(signinFailed);
    }),
  );
}

export function signUpConfirmCodeEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGNUP_CONFIRM_CODE),
    switchMap(({ username, verificationCode }: SignupConfirmCode) =>
      cognitoClient
        .signUpConfirmCode(username, verificationCode)
        .then(signupConfirmCodeSucceeded)
        .catch(signupConfirmCodeFailed),
    ),
  );
}

export function forgotPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.FORGOT_PASSWORD),
    switchMap(({ username }: ForgotPassword) =>
      cognitoClient.forgotPassword(username).then(forgotPasswordSucceeded).catch(forgotPasswordFailed),
    ),
  );
}

export function confirmPasswordEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.CONFIRM_PASSWORD),
    switchMap(({ username, verificationCode, newPassword }: ConfirmPassword) =>
      cognitoClient
        .confirmPassword(username, verificationCode, newPassword)
        .then(confirmPasswordSucceeded)
        .catch(confirmPasswordFailed),
    ),
  );
}

export function signOutEpic(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.SIGN_OUT),
    switchMap(({}: SignOut) => cognitoClient.signOut().then(signOutSucceeded).catch(signOutFailed)),
  );
}

export function initialUser(action$): Observable<LoginAction> {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.INIT_CLIENT),
    switchMap(({}) => getSession().then(initialClientSuccess).catch(initialClientFailed)),
  );
}
